<template>
  <el-tabs v-model="activeName" @tab-click="doChangeTab">
    <el-tab-pane label="课程收藏" name="1">
      <div v-for="item in courseList" :key="item.id">
        <!-- {{ item }} -->
				<el-card class="box-card">
				  <div class="list-header">
				    <div class="header-left">
				      <span>收藏时间：{{ item.create_time }}</span>
				    </div>
				    <div class="order-flag order-success" v-if="item.status == 1">
				      已购买
				    </div>
				    <div class="order-flag no-pay" v-else>未购买</div>
				  </div>
				  <ul class="list-con">
				    <li class="order-img">
				      <img
				        v-if="item.thumb"
				        :src="item.thumb"
				        :title="item.title"
				      />
				      <img
				        v-else
				        :src="require('@/assets/images/default-course-cover.png')"
				        :title="item.title"
				      />
				    </li>
				    <li class="order-title">
				      <span>{{ item.title }}</span>
				    </li>
				    <li class="order-type">
				      课程类型：{{
				        item.type == 1
				          ? "精品套餐"
				          : item.type == 2
				          ? "基础班级"
				          : "题库套餐"
				      }}
				    </li>
				  </ul>
				</el-card>
      </div>
    </el-tab-pane>
    <el-tab-pane label="题库收藏" name="2">
      <!-- <div v-for="item in examList" :key="item.id" @click="goQuestion(item)">
				<el-card class="box-card">
				  <div class="list-header">
				    <div class="header-left">
				      <span>收藏时间：{{ item.create_time }}</span>
				    </div>
				    <div class="order-flag order-success" v-if="item.status == 1">
				      已购买
				    </div>
				    <div class="order-flag no-pay" v-else>未购买</div>
				  </div>
				  <ul class="list-con">
				    <li class="order-img">
				      <img
				        v-if="item.thumb"
				        :src="item.thumb"
				        :title="item.title"
				      />
				      <img
				        v-else
				        :src="require('@/assets/images/default-course-cover.png')"
				        :title="item.title"
				      />
				    </li>
				    <li class="order-title">
				      <span>{{ item.title }}</span>
				    </li>
				    <li class="order-type">
				      课程类型：{{
				        item.type == 1
				          ? "精品套餐"
				          : item.type == 2
				          ? "基础班级"
				          : "题库套餐"
				      }}
				    </li>
				  </ul>
				</el-card>
      </div> -->
			<div id="mywrongtest">
				<div class="test-row" v-for="paper in examList" :key="paper.id" @click="goQuestion(paper)">
					<div class="title">{{paper.title}}</div>
					<div class="tag">
						<el-space>
							<el-tag type="primary">{{paper.type}}</el-tag>
							<el-tag type="warning">{{paper.spec}}</el-tag>
							<el-tag type="success">{{paper.course}}</el-tag>
						</el-space>
					</div>
					<el-divider border-style="dashed"></el-divider>
				</div>
			</div>
		</el-tab-pane>
  </el-tabs>
</template>

<script type="text/javascript">
import { getCollCombo, getCollPaper, getCollTest } from '@/api/user'
import {setSessionStorage} from '@/utils/auth'
export default {
  data() {
    return {
      // tab活动项
      activeName: '1',
      // 课程
      courseList: [],
      // 题库
      examList: [],

    }
  },
  created() {
    this.getList()

  },
  methods: {
    doChangeTab() {
      this.getList()
    },
    // 获取数据
    getList() {
      if (this.activeName == '1') {// 课程收藏
        getCollCombo().then(res => {
          this.courseList = res.data.filter(item => item != null)
        })
      } else {// 题库收藏
        getCollPaper().then(res => {
          this.examList = res.data
        })
      }
    },
		goQuestion(paper){
      // setSessionStorage("currentPaper",paper);
      // this.$router.push('/')
      if (paper.question_num == 0) {
        this.$message("当前试卷暂无试题");
        return;
      }
      // 试卷基本信息
      setSessionStorage("currentPaper", paper);
      window.open("/questionresult");
    }
  },
}
</script>

<style lang="scss" scoped>
	.box-card {
		padding: 10px;
		margin-bottom: 10px;
	}
	.list-header {
		padding: 10px 0px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		font-size: 12px;
		border-bottom: 1px solid #e8e8e8;
	}
	.list-header .header-left {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.list-header .header-left span {
		margin-right: 30px;
	}
	.list-header .order-flag.order-success {
		color: $success-color;
	}
	.list-header .order-flag.no-pay {
		color: $danger-color;
	}

	.list-con {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 100px;
		padding: 15px 0px;
		border-bottom: 1px solid #ccc;
		.order-img {
			width: 150px;
		}
		.order-title {
			margin-left: 20px;
			width: 350px;
			overflow: hidden; /*超出部分隐藏*/
			white-space: nowrap; /*不换行*/
			text-overflow: ellipsis; /*超出部分文字以...显示*/
		}
		.order-type {
			flex: 1;
			margin-left: 20px;
			font-size: 12px;
		}
		.order-price {
			flex: 1;
			font-size: 12px;
			.price {
				font-size: 14px;
				color: $price-color;
			}
		}
	}
	#mywrongtest{
  .test-row{
    .title{
      font-size:16px;
      font-weight: bold;
      height:40px;
      line-height: 40px;
    }
    .tag{
      height:30px;
      line-height: 30px;
    }
  }
}
</style>
